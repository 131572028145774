@font-face {
  font-family: "InterBlack";
  font-weight: "900";
  src: local("InterBlack"),
    url(./assets/fonts/Inter-Black.ttf) format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  font-weight: "800";
  src: local("InterExtraBold"),
    url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "InterBold";
  font-weight: "700";
  src: local("InterBold"), url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "InterSemiBold";
  font-weight: "600";
  src: local("InterSemiBold"),
    url(./assets/fonts/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "InterMedium";
  font-weight: "500";
  src: local("InterMedium"),
    url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "InterRegular";
  font-weight: "400";
  src: local("InterRegular"),
    url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "InterLight";
  font-weight: "300";
  src: local("InterLight"),
    url(./assets/fonts/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "InterExtraLight";
  font-weight: "200";
  src: local("InterExtraLight"),
    url(./assets/fonts/Inter-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "InterThin";
  font-weight: "100";
  src: local("InterThin"), url(./assets/fonts/Inter-Thin.ttf) format("truetype");
}

* {
  font-family: InterMedium;
  /* color: #110623; */
}

h1 {
  font-family: InterBlack;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

[data-reactroot] {
  height: 100% !important;
}

a,
p {
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  height: 50px;
  margin-right: 15px;
  width: 200px;
  max-width: 200px;
}

footer > * {
  color: #fff;
}

/* add in helpers */
.flex-1 {
  flex: 1;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-block {
  display: block;
}

.color-white {
  /* todo: remove this and update all instance */
  color: #fff;
}

button.test {
  border-radius: 10px;
  background: #6823d1;
  padding: 10px 25px;
  right: 5px;
  color: #fff;
}

.rightContainer {
  padding: 120px;
}

/* hidden to allow navbar & tabcontexts scrolling without showing page overflows */
.main-container {
  overflow: hidden;
}

@media (max-width: 500px) {
  .rightContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (max-width: 800px) {
  .rightContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.container {
  display: flex;
}

a {
  text-decoration: none;
}

a.user-photo {
  border: solid 5px #fff;
  border-radius: 8px;
}

.custom-container {
  padding: 50px !important;
}

/* About Section */

@media screen and (max-width: 390px) {
  /* .about-text-container {
    padding: 0px 23px;
  } */

  .about-text-container p {
    font-size: 14px;
  }
}

/* Simon */

input:focus ~ .input-style {
  top: 1px;
  font-size: 11px;
}

.registration-text {
  padding-top: 50px !important;
}

@media screen and (min-width: 220px) {
  .responsive-font-size {
    font-size: 32px;
  }

  .responsive-font-p {
    font-size: 14px;
  }

  .responsive-container-2 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .button-container-img {
    width: 100px;
    padding-top: 50px;
  }

  .card-container {
    padding-top: 20px;
    margin: 0 10px;
    display: flex;
    overflow-x: auto;
    gap: 10px;
    -ms-overflow-style: none;
  }

  .card-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome, removes horizontal scroll bar */
  }

  .contact-container {
    display: flex;
    flex-direction: column;
  }

  .container-style-4 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    position: relative;
    padding-top: 100px;
    text-align: center;
  }

  .form-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .formContainer {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .input-container {
    gap: 30px;
    display: flex;
    flex-direction: column;
  }

  .map-container {
    width: 100%;
  }

  .map-contact-header {
    font-size: 40px;
    padding: 100px 0 100px 10px;
    width: 100%;
    text-transform: uppercase;
  }

  .section-style {
    text-align: center;
  }

  .seller-header {
    padding: 50px 10px 0;
  }

  .slide {
    width: 320px;
    flex-shrink: 0;
  }

  .text-area-style {
    min-width: 268px;
  }

  .image-style-container .image-style {
    border-radius: 10px;
    height: auto;
    max-height: 200px;
    min-height: 100px;
    max-width: 100%;
    /* min-width: 100px; */
    object-fit: cover;
    width: 100%;
  }

  .item-style {
    border-radius: 10px;
    background: #ffffff;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 10px 0;
    position: relative;
  }

  .item-info-style {
    padding: 0;
    width: 100%;
  }

  .home-map #map {
    display: none;
  }

  .message-list {
    display: none;
  }

  @media screen and (min-width: 900px) {
    .home-map #map {
      display: block;
    }
    .message-list {
      display: flex;
    }
  }

  @media screen and (min-width: 940px) {
    .card-container {
      overflow-x: initial;
      margin-bottom: 200px;
    }

    /* ImagePreSigned Styling */
    .image-style-container .image-style {
      height: auto;
      max-height: 100px;
      min-height: 100px;
      max-width: 100px;
      min-width: 100px;
      object-fit: cover;
      width: auto;
    }

    .item-style {
      border-radius: 10px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 20px;
      margin: 10px 0;
      position: relative;
    }

    .item-info-style {
      padding: 0 0 0 30px;
      width: 100%;
    }

    .formContainer {
      margin-top: 24px !important;
      margin-bottom: 24px !important;
    }

    .heading-large {
      width: 70%;
      margin: auto;
      padding: 50px 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .card-container {
      position: relative;
      top: 100px;
      margin-bottom: 200px;
      gap: 50px;
      justify-content: center;
    }

    .seller-header {
      padding: 150px 0 0 0;
      text-align: center;
      max-width: 550px;
      margin: auto;
    }

    .mid-card {
      position: relative;
      bottom: 50px;
    }
  }

  @media screen and (min-width: 1120px) {
    .responsive-font-size {
      font-size: 40px;
    }

    .responsive-font-p {
      font-size: 18px;
    }

    .responsive-container-2 {
      padding-left: 100px;
    }

    .button-container-img {
      padding-top: 0;
    }

    .contact-container {
      flex-direction: row;
    }

    .container-style-4 {
      flex-direction: row;
      padding: 100px;
      text-align: left;
    }

    .input-container {
      flex-direction: row;
    }

    .text-area-style {
      min-width: 560px;
    }

    .faq-container {
      text-align: left;
    }

    .form-container {
      margin: initial;
      text-align: left;
      padding-top: 150px;
      padding-bottom: 150px;
    }

    .map-container {
      width: 50%;
    }

    .map-contact-header {
      font-size: 56px;
      width: 90%;
      padding: 100px;
      text-align: left;
    }
  }

  .section-style {
    text-align: initial;
  }

  .map-page {
    margin-top: 50px !important;
  }

  .my-leaflet-map-container img {
    max-height: none;
  }

  @media screen and (min-width: 1120px) {
    .MuiContainer-root .MuiContainer-maxWidthLg {
      max-width: 100%;
    }
  }
}
